import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDish} from "../../utils/contexts/dish-context";
import Button from "../UI/Button";
import BaseInput from "../UI/BaseInput";
import Loading from "../UI/Loading";
import Description from "../UI/Text/Description";
import {useApi} from "../../utils/hooks/api";
import {doGetIngredients, doGetOffData} from "../../logic/barcode";
import {useNavigate} from "react-router-dom";
import {useToast} from "../../utils/hooks/toast-hook";
import {doGetGS1Products} from "../../logic/gsi_products";

const BarcodeInput = () => {
    const {displayError, displayMessage} = useToast()
    const {t, i18n} = useTranslation();
    const {call, loading} = useApi();
    const {setDish, dish, setOffData, offData, barcode, setBarcode, gs1Data, setGs1Data} = useDish();
    const navigate = useNavigate();
    const [letScan, setLetScan] = useState(true)

    const handleResetForm = () => {
        setDish(undefined)
        setBarcode(undefined)
        setOffData(undefined)
        setLetScan(true)
    }


    // const handleScan = async (barcode) => {
    //     setBarcode(barcode);
    //     await call(
    //         () => doGetIngredients(barcode, i18n.language),
    //         async (res) => {
    //             if (res && res.data)
    //                 if (res.data.length !== 0) {
    //                     setDish(res.data);
    //                     setLetScan(false)
    //                 } else {
    //                     // await handleGetOffData();
    //                     await handleGetIGsiData();
    //                 }
    //         },
    //     );
    // };


    // provvisorio, ripristinare quello sopra
    const handleScan = async (barcode) => {
        setBarcode(barcode);
        await handleGetIGsiData();
    };

    const handleGetIngredients = async () => {
        await call(
            () => doGetIngredients(barcode, i18n.language),
            async (res) => {
                if (res && res.data) {
                    if (res.data.length !== 0) {
                        setDish(res.data);
                        setLetScan(false)
                    } else {
                        // await handleGetOffData();
                        await handleGetIGsiData();
                    }
                }
            },
        );
    };

    const handleGetOffData = async () => {
        await call(
            () => doGetOffData(barcode, i18n.language),
            (res) => {
                if (res && res && res.name) {
                    setLetScan(false)
                    setOffData(res);
                    navigate('/off_data');
                } else {
                    setLetScan(false)
                    // displayMessage('prodotto non trovato');
                }
            },
            () => {
                console.log('error')
            }
        );
    };

    const handleGetIGsiData = async () => {
        // await call(
        //     () => doGetOffData(barcode, i18n.language),
        //     (res) => {
        //         if (res && res && res.name) {
        //             setLetScan(false)
        //             setOffData(res);
        //         }
        //
        //     },
        //     () => {
        //         console.log('error')
        //     }
        // );
        await call(
            () => doGetGS1Products(barcode),
            async (res) => {
                if (res) {
                    setGs1Data(res)
                    navigate('/gs1_data');
                }
            },
        );
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // await handleGetIngredients();
        await handleGetIngredients()
    };


    // const handleSubmit = async () => {
    //     await call(
    //         () => doGetIngredients(barcode, i18n.language),
    //         async (res) => {
    //             if (res && res.data)
    //                 if (res.data.length !== 0) {
    //                     setDish(res.data);
    //                 } else {
    //                     await call(
    //                         () => doGetOffData(barcode, i18n.language),
    //                         (res) => {
    //                             if (res && res) {
    //                                 if (res.length > 0) {
    //                                     setOffData(res);
    //                                     navigate('/off_data');
    //                                 } else {
    //                                     displayMessage('prodotto non trovato');
    //                                 }
    //                             }
    //                         },
    //                         () => {
    //                             console.log('error')
    //                         }
    //                     )
    //                     console.log('ciao')
    //
    //                 }
    //         },
    //     );
    // };

    return (

        <div>
            <Description>{t('gdo.barcode_input_text')}</Description>
            <form className="flex flex-col sm:flex-row gap-2"
                  onSubmit={handleSubmit}

            >
                <BaseInput className="w-full"
                           placeholder={t('gdo.barcode_input_placeholder')}
                           value={barcode || ''}
                           required={true}
                           onChange={(e) => setBarcode(e.currentTarget.value)}
                />
                {loading ? (
                    <Loading label="Computing..."/>
                ) : (
                    !letScan ? (
                        <Button type='button' onClick={handleResetForm}>
                            {t('gdo.scan_another_barcode')}
                        </Button>
                    ) : (
                        <Button type='button'
                                onClick={handleSubmit}
                        >
                            {t('gdo.barcode_input_button')}
                        </Button>
                    )
                )}

            </form>
            {!dish && letScan &&
                <>
                    {/*<BarcodeScanner onScan={handleScan} stopScan={!letScan}/>*/}
                    {/*<UserInfoSelectors/>*/}
                </>

            }
            {!offData && !letScan && !dish && <>
                <div style={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh'
                }}>
                    <p className={'mb-8'}><strong>PRODOTTO NON TROVATO</strong></p>
                </div>
            </>}

        </div>

    );


};

export default BarcodeInput;
