import apiV3 from "../utils/apiV3";


async function doGetGS1Products(product_code = 'en') {
    let response = await apiV3.get(`gs1/barcode/${product_code}/data`);
    if (response) {
        return response.data;
    }
}

async function doGetProductGuessIngredients(text_ingredients) {
    return await apiV3.get(`products/guessingredients/?text_ingredients=${text_ingredients}`);
}

export {
    doGetGS1Products,
    doGetProductGuessIngredients,
}
