import React from 'react';
import {FaTrashAlt} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import {useDish} from "../../../utils/contexts/dish-context";
import CompoundName from "./CompoundName";

const SelectedIngredient = ({ingredient, onDelete}) => {

    const {dish} = useDish();
    const {t} = useTranslation();

    const handleDelete = () => {
        onDelete && onDelete(ingredient);
    }

    const isIngredientValid = () => {
        return dish.ingredients.some(ingr => ingr.id === ingredient.id);
    }

    return (
        <div title={ingredient.compound === 1 ? "Ingrediente composto" : ""}
             className={`${ingredient.compound === 1 ? "bg-yellow-200" : "bg-gray-200"} rounded p-1 ${isIngredientValid() ? '' : 'bg-opacity-30'}`}>
            <div className='flex flex-row items-center justify-between gap-2'>
                {ingredient.compound === 1 ?
                    <CompoundName ingredient={ingredient} className=""/>
                    :
                    // <div>{ingredient.text ?? ingredient.name}</div>
                    <div className='flex-col'>
                        <div>{ingredient.text ?? ingredient.name}</div>
                        <div className="text-xs block">{ingredient.text ?? ingredient.it_name}</div>
                    </div>
                }
                {onDelete &&
                    <FaTrashAlt className="cursor-pointer hover:text-red-500" title="Rimuovi ingrediente"
                                onClick={handleDelete}/>}
            </div>
            <div className="text-xs leading-none text-gray-500">{t('weight')}: {ingredient.weight ?? 0}g</div>
        </div>
    );
};

export default SelectedIngredient;
